import axios from 'axios'
import Cookies from 'js-cookie'

const token = Cookies.get('centurium.token')

export const api = axios.create({
  baseURL: 'https://api.centuriumhost.com.br',
})

api.interceptors.request.use((config) => {
  return config
})

if (token) {
  api.defaults.headers.common.Authorization = `Bearer ${token}`
}
