import { extendTheme } from '@chakra-ui/react'

import { mode } from '@chakra-ui/theme-tools'

const theme = {
  config: {
    initialColorMode: 'dark',
    cssVarPrefix: 'moonkode',
  },
  fonts: {
    body: 'Poppins, sans-serif',
  },
  styles: {
    global: (props: any) => ({
      body: {
        bg: mode('#fff', '#101213')(props),
      },
    }),
  },
}

export default extendTheme(theme)
