import { IUser } from '../interfaces/IUser'
import { api } from '../services/api'
import { addMinutes } from 'date-fns'
import Cookies from 'js-cookie'
import Router from 'next/router'
import { createContext, useState, useEffect } from 'react'
import { toast } from 'react-toastify'

type SignInData = {
  email: string
  password: string
  remember: boolean
  setLoading: any
}

type AuthContextType = {
  isAuthenticated: boolean
  user: IUser | null
  signIn: (data: SignInData) => Promise<void>
}

export const AuthContext = createContext({} as AuthContextType)

export function AuthProvider({ children }: any) {
  const [user, setUser] = useState<IUser | null>(null)

  const isAuthenticated = !!user

  const recoverUser = async () => {
    await api
      .get('/v1/users/me')
      .then((response) => {
        setUser(response.data.user)
      })
      .catch(() => {
        setUser(null)
      })
  }

  useEffect(() => {
    const token = Cookies.get('centurium.token')

    if (token) recoverUser()
  }, [])

  async function signIn({ email, password, remember, setLoading }: SignInData) {
    await api
      .post('/v1/authentication', {
        email,
        password,
        remember,
      })
      .then((response: any) => {
        Cookies.set('centurium.token', response.data.token, {
          expires: addMinutes(new Date(), remember ? 360 : 180),
        })

        api.defaults.headers.common.Authorization = `Bearer ${response.data.token}`

        setUser(response.data.user)

        toast('Logado com sucesso.', {
          type: 'success',
        })

        Router.push('/app')
      })
      .catch((error) => {
        toast(error.response.data.message, {
          type: 'error',
        })

        setLoading(false)
      })
  }

  return (
    <AuthContext.Provider value={{ user, isAuthenticated, signIn }}>
      {children}
    </AuthContext.Provider>
  )
}
