import type { AppProps } from 'next/app'

import { ChakraProvider } from '@chakra-ui/react'

import theme from '../styles/theme'
import Head from 'next/head'

import { AuthProvider } from '../contexts/AuthContext'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <AuthProvider>
      <ChakraProvider theme={theme}>
        <Head>
          <title>CenturiumHost</title>
          <meta name="description" content="CenturiumHost" />
        </Head>
        <ToastContainer position="bottom-center" theme="dark" />
        <Component {...pageProps} />
      </ChakraProvider>
    </AuthProvider>
  )
}

export default MyApp
